// cookies.js

function setCookie(name, value, expires) {
    let expiresString = "";
    if (expires) {
        expiresString = "; expires=" + expires.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expiresString + "; path=/";
}

// Function to get a cookie by name
function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

// Function to check if a cookie exists and its value
function checkCookie(name) {
    const cookieValue = getCookie(name);
    return cookieValue !== null;
}

// Function to delete a cookie by name
function deleteCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
}

function setCookieToExpireAtMidnight(name, value) {
    const now = new Date();
    
    const expires = new Date();
    expires.setHours(24, 0, 0, 0);
    console.log(expires);

    setCookie(name, value, expires);
}

// Function to get the workout streak from the cookie
export function getWorkoutStreak() {
    const streak = getCookie('workoutStreak');
    return streak ? parseInt(streak, 10) : 0;
}

// Function to set or update the workout streak
function updateWorkoutStreak() {
    let streak = getWorkoutStreak();
    
    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    expires.setHours(24, 0, 0, 0);

    streak = streak > 0 ? streak + 1 : 1;

    setCookie('workoutStreak', streak, expires);
}

export function checkWorkoutCompletion() {
	return getCookie('workoutCompleted') === 'true';
}

export function markWorkoutAsCompleted() {
	setCookieToExpireAtMidnight('workoutCompleted', 'true');
    updateWorkoutStreak();
}

export function setHideIntroOverlay() {
	const date = new Date();
	date.setDate(date.getDate() + 30);
	setCookie('hideIntroOverlay', 'true', date);
}

export function getHideIntroOverlay() {
	return getCookie('hideIntroOverlay') === 'true';
}